import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useState } from "react";

import { useLocalPracticeId } from "src/auth/utils/practice";

const Home: NextPage = () => {
  const router = useRouter();
  const [hasRun, setHasRun] = useState(false);
  const practiceId = useLocalPracticeId();

  if (!hasRun && practiceId !== null) {
    setHasRun(true);
    router.replace({
      pathname: `/${practiceId}/queues/teams`,
    });
  }

  return null;
};

export default Home;
